.title-top-index {
  color: #fff;
}

.text-top-index {
  color: #fff;
  background-color: #062f4799;
  border-radius: 10px;
  padding: 20px;
}

.btn-demo {
  background-color: #e2c838;
}

.header-weight {
  font-weight: 400;
}

.feature-header {
  color: #6c757d;
  font-size: 24px;
  font-weight: 400;
}

.feature-text {
  text-align: center;
  font-size: 18px;
}

.detail-text {
  text-align: start;
  font-size: 16px;
}

.nav-link.active {
  font-size: 24px;
}

.nav-link {
  color: #fff;
  font-size: 20px;
}

.question-title {
  font-size: 20px;
}

.question-text {
  font-size: 14px;
}

.btn-email {
  background-color: #e2c838;
}

.text-contact {
  font-size: 16px;
}

.text-highlight {
  color: #e2c838;
  font-weight: 600;
}

.nav-link:hover {
  text-decoration: underline #e2c838;
  text-decoration-thickness: 2px;
}

.hero-section:after {
  background-image: url("login2.5377528f.jpg");
}

@media (max-width: 400px) {
  #top-bar-logged-in {
    width: 100%;
    flex-direction: column;
    display: flex;
  }
}

.image-container {
  filter: drop-shadow(0 0 8px #000000bf);
  transition: transform .3s, box-shadow .3s;
}

.image-container:hover {
  transform: scale(1.05);
}

/*# sourceMappingURL=index.4f525259.css.map */
