
.title-top-index {
    color: white;
}

.text-top-index {
    color: white;
    background-color: rgba(6, 47, 71, 0.6);
    padding: 20px;
    border-radius: 10px;
}

.btn-demo {
  background-color: rgb(226, 200, 56); 
}

.header-weight {
    font-weight: 400;
}

.feature-header {
    font-size: 24px;
    color: rgb(108, 117, 125);
    font-weight: 400;
}

.feature-text {
    font-size: 18px;
    text-align:center
}

.detail-text {
    font-size: 16px;
    text-align: start;
}

.nav-link.active{
    font-size: 24px;
}

.nav-link {
    font-size: 20px;
    color: white;
}

.question-title {
    font-size: 20px;
}

.question-text {
    font-size: 14px;
}

.btn-email {
    background-color: rgb(226, 200, 56);
}

.text-contact {
    font-size: 16px;
}

.text-highlight {
    color: rgb(226, 200, 56);
    font-weight: 600;
}

.nav-link:hover {
    text-decoration: underline;
    text-decoration-color: rgb(226, 200, 56);
    text-decoration-thickness: 2px;
}

.hero-section::after {
    background-image: url("../images/login/login2.jpg");
}

@media (width<=400px) { 
    #top-bar-logged-in {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

/* images */

.image-container {
    filter: drop-shadow(0 0 8px rgba(0,0,0, 0.75));
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container:hover {
    transform: scale(1.05);
}



  